<template>
<!-- 首页预售和超级秒杀 列表页 -->
<div>
<el-col :span="24" class="item"  v-if="productType=='TimeBuy'">
  <!-- 首页超级秒杀  -->
    <div class="imgWrap"  @click="jumpTimeBuyDetail(info.id)">
      <img :alt="info.name | defaultName"    :src="info.images?info.images + picSuffix : defaultImage"/>
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <!-- <p class="msg" :title="info.description">{{info.detail}}{{info.description}}</p> -->
    <div class="msgBox">
      <img  src="@/assets/TimeBuyIcon.png" alt=""/>
      剩余库存：{{info.comboTotal-info.comboUsage}}{{info.productList[0].unit}}
    </div>
    <div class="lineBox">
      <div class="priceBox">
        <p class="price">
        <!-- info.comboPrice -->
          限时秒杀价:￥<b>{{info.auditUserStatus===0?"***":comboPrice}}</b>
        </p>
        <p class="delPrice"> <del>原价：{{ info.auditUserStatus===0?"***":info.comboOriginalPrice}}</del></p>
      </div>
      <p class="msgOne" v-if="new Date(this.info.beginDate).getTime() < new Date().getTime()" @click="jumpTimeBuyDetail(info.id)">抢</p>
      <p class="msgTwo"  v-else>抢</p>
    </div>

      <p class="msgP" v-if="new Date(this.info.beginDate).getTime() < new Date().getTime()"></p>
      <p class="msgP"  v-else>{{info.beginDate}} 开抢</p>
  </el-col>



  <el-col :span="24" class="item"  v-if="productType=='OpenBooking'">

    <!-- 首页预售 -->
    <div class="imgWrap"  @click="JumpOpenBookingDetail(info.promotionId)">
      <img
        :alt="info.name | defaultName"
        :src="url?url + picSuffix : defaultImage"
      />
      <!-- <img  class="AdvanceSaleBg" src="@/assets/AdvanceSale.png" alt="">
      <div class="bottomRightBox">
          预计{{info.presellDeliveryDate }}发货
      </div> -->
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <p class="msg">{{info.specification}}</p>
    <p class="msg" :title="info.manufacturerName">{{info.manufacturerName}}</p>
    <div class="priceBoxs">
      <!-- <span class="labelBox">
        套餐价
        <div class="arrow-right"></div>
      </span> -->
      <span class="price">
         <!-- {{ info.auditUserStatus===0?"***":info.price  | capitalize }} -->
         ￥{{Number(numPriceArr[numPriceArr.length - 1].price).toFixed(2)}} 起
        </span>
      <del class="delPrice">
         {{ info.auditUserStatus===0?"***":info.originalPrice  | capitalize }}
       </del>
    </div>
      <p class="msg">热销中</p>
  </el-col>
</div>
  
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  // background: #fff;
  // padding-left:8px !important;
  // padding-right:8px!important;
  // padding-bottom:10px;
  width: 223px;
  background: #fff;
  float: left;
  margin:5px 9px 5px 0;
  padding: 10px;
  position: relative;
   
  &:hover{
    box-shadow: 0px 4px 10px 0px #bfbfbf;
		border-radius:7px;
  }
  .price{
          font-family: smallYuan;
        }
  .imgWrap {
    overflow: hidden;
    position: relative;
    .AdvanceSaleBg{
      display: block;
        width:184px;
        height:184px;
        z-index: 2;
        position:absolute;
        top:0px;
        right:0px;
        &:hover {
          box-shadow: 10px 6px 6px -4px #dedada;
        }
    }
    .bottomRightBox{
      position: absolute;
      bottom:8px;
      right: 0;
      z-index:3;
      width:63.5%;
      color: #FFFFFF;
      font-size:12px;
    }
    img {
      display: block;
      width: 100%;
      height:200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .shopName {
    padding-top:10px;
    height: 22px;
    color: #333;
    font-size:18px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow:hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp:2;
    // -webkit-box-orient: vertical;
  }
  .lineBox{
    margin-top:5px;
    .priceBox{
      width:calc( 100% - 72px);
      float: left;
      background: #fcecef;
      color: #ff4d00;
      padding:5px 10px;
      height:40px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      
      .price{
            width: 100%;
            font-size:13px;
            font-weight: 600;
            display: inline-block;
            height: 20px;
            b{
              font-size: 17px;
            }
        }
        .delPrice{
            width: 100%;
            font-size: 12px;
            height:18px;
        }
    }
   
    .msgOne{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: block;
      // width: calc( 30% - 20px );
      // height:53px;
      width:50px;
      height:50px;
      background: #FF3333;
      color: #fff;
      float: left;
      text-align: center;
      line-height:50px;
      font-size:16px;
      cursor: pointer;
    }
    .msgTwo{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: block;
      width: 50px;
      height:50px;
      background: rgba(255, 51, 51, 0.5);
      color: #fff;
      float: left;
      text-align: center;
      line-height:50px;
      font-size:16px;
      cursor: default;
    }
  }
  .msgP{
      color: #929598;
      font-size: 12px;
      line-height:30px;
      height:30px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  .priceBoxs {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    // .labelBox {
    //     display: inline-block;
    //   background: #ff3333;
    //   padding: 0 5px;
    //   color: #fff;
    //   text-align: center;
    //   line-height:20px;
    //   position: relative;
    //   .arrow-right {
    //         position: absolute;
    //         right: -4px;
    //         width: 6px;
    //         height: 6px;
    //         top: 6px;
    //         background: #ff3333;
    //         border-top: 2px solid #ff3333;
    //         border-right: 2px solid #ff3333;
    //         transform: rotate(45deg);
    //   }
    // }
    .price{
        color: #ff3333;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:10px;
        color: #929598;
        font-size: 12px;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        line-height: 20px;
        width: 100%;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
  }
  .msgBox{
        margin-top: 10px;
        position: relative;
        img{
          display: block;
          width:60px;
          height:auto;
          position: absolute;
          bottom:3px;
          left: 0;
        }
        text-indent:70px;
        color: #929598;
        font-size: 12px;
        line-height: 20px;
        width: 100%;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ComboItem",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 0,
      // pics: 1,
      defaultImage: defaultImage,
      promotionMap: "",
      url:'',
      comboPrice:'',//超级秒杀价格
      numPriceArr:[],
    };
  },
  props: {
    info: {
      type: Object,
    },
    productType: {
      type: String,
    },
  },
  methods: {
    // 限时购
    jumpTimeBuyDetail(id) {
      // this.$router.push({
      //    name: "ActivityModuleDetail",
      //   query: { id: id,ActivityType:2},
      // });
      const routeData = this.$router.resolve({
         name: "ActivityModuleDetail",
        query: { id: id,ActivityType:2},
      });
      window.open(routeData.href, '_blank');
    },
    // CommonJumpDetail(id,isRecentExpiration) {
    //   this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration<0?0:isRecentExpiration } });
    // },

    // 预售
    JumpOpenBookingDetail(promotionId) {
      // this.$router.push({ name: "ActivityModuleDetail", query: { promotionId: promotionId,ActivityType:1 } });
      const routeData = this.$router.resolve({ name: "ActivityModuleDetail", query: { promotionId: promotionId,ActivityType:1 } });
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    "info": function(newValue) {
      if (newValue) {
        if(newValue.numPrice && newValue.numPrice!=''){
          this.numPriceArr=JSON.parse(newValue.numPrice)
        }
      }
    },
  }, 
  created() {
    if(this.productType=='OpenBooking'){
      this.url=this.info.pic.split(",")[0]
      console.log('预售',this.info);
      if(this.info.numPrice && this.info.numPrice!=''){
        this.numPriceArr=JSON.parse(this.info.numPrice)
      }
    }else{
      console.log('超级秒杀',this.info);
      if(this.info.numPrice && this.info.numPrice!=''){
            this.numPriceArr=JSON.parse(this.info.numPrice)
            // 倒序排序
            this.numPriceArr.sort(function(a, b) {  
              return b.num - a.num;  
            });  
            console.log(this.numPriceArr);
            // +'起'
            this.comboPrice=Number(this.numPriceArr[0].price).toFixed(2)
          }
    }
  
  },
  updated() {},
};
</script>
